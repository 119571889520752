import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import OrderHistoryApi from '../../../service/OrderHistoryApi'
import OrderInfoApi from '../../../service/OrderInfoApi'
import DashboardOrderInfoModal from '../DashboardOrderInfoModal'
import './orderHistoryTable.scss'
import Pagination from 'react-js-pagination'
import Loader from '../../../utils/loader/Loader'
export const statusCheck = (status: number) => {
    switch (status) {
        case 1:
            return (
                <button className="btn btn-danger orderbtnbg">Ordered</button>
            )
        case 2:
            return (
                <button className="btn btn-warning inprocessbtnbg">
                    In Progress
                </button>
            )
        case 3:
            return (
                <button className="btn btn-warning dispatchedbtnbg">
                    Dispatched
                </button>
            )
        case 4:
            return (
                <button className="btn btn-success deliveredbtnbg">
                    Delivered
                </button>
            )
        case 5:
            return (
                <button className="btn btn-success invoicedbtnbg">
                    Invoiced
                </button>
            )
        case 6:
            return <button className="btn btn-success paidbtnbg">Paid</button>
    }
}
export const convertDate = (dateValue: number) => {
    // const date = new Date(dateValue);
    // const year = date.getFullYear();
    // const month = ('0' + (date.getMonth() + 1)).slice(-2);
    // const day = ('0' + date.getDate()).slice(-2);

    // return `${month}/${day}/${year}`;
    const date = new Date(dateValue)
    const year = date.getFullYear()
    const month = ('0' + (date.getMonth() + 1)).slice(-2)
    const day = ('0' + date.getDate()).slice(-2)
    const hours = ('0' + date.getHours()).slice(-2)
    const minutes = ('0' + date.getMinutes()).slice(-2)
    const seconds = ('0' + date.getSeconds()).slice(-2)

    let timeFormat = 'AM'
    let formattedHours = parseInt(hours)

    if (formattedHours >= 12) {
        timeFormat = 'PM'
        if (formattedHours > 12) {
            formattedHours -= 12
        }
    }

    return `${month}/${day}/${year} ${formattedHours}:${minutes}:${seconds} ${timeFormat}`
}
const TableHeader = () => {
    const token = sessionStorage.getItem('token')
    const [orderData, setOrderData] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)
    const [total, setTotal] = useState<number>(0)
    const navigate = useNavigate()
    const path = window.location.pathname.slice(1)

    const fetchOrderData = async () => {
        try {
            setLoading(true)
            const data = await OrderHistoryApi(token, pageSize, currentPage)
            if (data?.status) {
                setOrderData(data?.data)
                setTotal(data.totalCount)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }
    useEffect(() => {
        fetchOrderData()
    }, [currentPage, pageSize])

    function handlePageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setCurrentPage(1)
        setPageSize(Number(e.target.value))
    }

    const handleClick = async (orderId: any) => {
        // try {
        //     const result = await OrderInfoApi(orderId, token)
        //     if (result?.status) {
        navigate('/orderSummary', { state: orderId })
        //     }
        // } catch (error) {
        //     console.log(error)
        // }
    }
    if (loading) {
        return <Loader startLoading={loading} />
    }
    return (
        <>
            {orderData.length > 0 ? (
                <div className="container col-sm-12 mt-5">
                    {path === 'dashboard' ? null : (
                        <span className="mb-3 mt-3">
                            <p className="orders">ORDER HISTORY</p>
                        </span>
                    )}
                    <div className="table-responsive containerDetail">
                        <table className="table">
                            <thead className="table-header">
                                <tr>
                                    <th className="table-header">Order Id</th>
                                    <th className="table-header">
                                        Number Of Boxes
                                    </th>
                                    <th className="table-header">Order Date</th>
                                    <th className="table-header">
                                        Invoices Date
                                    </th>
                                    <th className="table-header">Status</th>
                                    <th className="table-header"></th>
                                </tr>
                            </thead>
                            <tbody className="orderDetails">
                                {orderData?.map((item: any, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="">
                                                {item?.orderId}
                                            </td>
                                            <td>{item?.totalBoxes}</td>
                                            <td>
                                                {convertDate(item?.orderDate)}
                                            </td>
                                            <td>
                                                {convertDate(item?.invoice)}
                                            </td>
                                            <td>{statusCheck(item?.status)}</td>
                                            <td>
                                                {path === 'dashboard' ? (
                                                    <DashboardOrderInfoModal
                                                        id={item?._id}
                                                    />
                                                ) : (
                                                    <button
                                                        onClick={() =>
                                                            handleClick(
                                                                item?._id
                                                            )
                                                        }
                                                        className="btn btn-success order-info"
                                                    >
                                                        <i
                                                            className="fa fa-info-circle"
                                                            aria-hidden="true"
                                                        ></i>
                                                        &nbsp;Order Info
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    {path === 'dashboard' ? (
                        <></>
                    ) : (
                        <div className="table__pagination__container">
                            {total > pageSize && (
                                <Pagination
                                    activePage={currentPage}
                                    itemsCountPerPage={pageSize}
                                    totalItemsCount={total}
                                    onChange={(page: any) => {
                                        setCurrentPage(page)
                                    }}
                                />
                            )}
                            {orderData.length > 0 && (
                                <select
                                    className="pagination__select"
                                    value={pageSize}
                                    onChange={handlePageSizeChange}
                                >
                                    {[10, 20, 30].map((size) => (
                                        <option
                                            className="dropdwon_Option"
                                            key={size}
                                            value={size}
                                        >
                                            Show {size}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </div>
                    )}
                </div>
            ) : (
                <div className="container mt-5">
                    <div className=" jumbotron rounded">
                        <h2 className="NoDataFound">No order details found</h2>
                    </div>
                </div>
            )}
        </>
    )
}

export default TableHeader
