import React, { useState, SyntheticEvent, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginApi from '../../service/auth/LoginApi';
import InputField, { ValueType } from '../Fields/InputField';
import { EmailValidation, PasswordValidation } from './AuthFieldValidation';
import ForgotPassword from './ForgotPassword';
import LabelError from './LabelError';
import ReCAPTCHA from 'react-google-recaptcha';
import { AuthContext } from '../context/UserContext';
import { LoginType, validationType } from '../../Types';
import clsx from 'clsx';
import { EnableDisableLogin } from './EnableDisable';
import { errorToaster, successToaster } from '../Toast';
``
const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY || ''

const loginState = {
    email: '',
    password: '',
};
const initialValue = {
    errorMsg: '',
    error: true,
    buttonDisable: '',
};
const Login = () => {
    const [logindata, setLoginData] = useState<LoginType>(loginState);
    const [isemail, setIsEmail] = useState<validationType>(initialValue);
    const [ispassword, setIsPassword] = useState<validationType>(initialValue);
    const [isdisable, setIsDisable] = useState(true);
    const navigate = useNavigate();
    const [captchaToken, setCaptchaToken] = useState<string>('');
    const { setAuthLink } = useContext(AuthContext);
    const handleChange = (e: SyntheticEvent, v: ValueType) => {
        const { name, value } = v;
        if (name === 'email') {
            const evalid = EmailValidation(value);
            setIsEmail((old: any) => ({
                ...old,
                errorMsg: evalid?.errorMsg,
                error: evalid?.error,
                buttonDisable: evalid?.disableError,
            }));
        } else if (name === 'password') {
            const pvalid = PasswordValidation(value);
            setIsPassword((old: any) => ({
                ...old,
                errorMsg: pvalid?.errorMsg,
                error: pvalid?.error,
                buttonDisable: pvalid?.disableError,
            }));
        }
        setLoginData((prevValue: LoginType) => ({
            ...prevValue,
            [name]: value,
        }));
    };
    useEffect(() => {
        const check = EnableDisableLogin(
            isemail?.buttonDisable,
            ispassword?.buttonDisable,
            captchaToken?.length
        );
        setIsDisable(check);
    }, [isemail, ispassword, captchaToken]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsDisable(true);
        const result = await LoginApi(logindata);
        if (result?.status) {
            sessionStorage.setItem('token', result?.token);
            sessionStorage.setItem('username', result?.data?.name);
            sessionStorage.setItem('userId', result?.data?._id);
            sessionStorage.setItem('isProfileCompleted', (result?.data.isProfileCompleted))

            setAuthLink((prev: any) => ({ ...prev, Token: result?.token }));
            successToaster(result?.message);
            setIsDisable(false);
            navigate('/dashboard');
        } else {
            errorToaster(result?.message);
            setIsDisable(false);
        }
    };
    const handleSignUp = () => {
        setAuthLink((prev: any) => ({ ...prev, AuthLink: 'Registration' }));
    };

    const getTokenFromCaptcha = (value: any) => {
        setCaptchaToken(value);
    };
    const handleBlur = (name: string, value: string) => {
        console.log(name, value);
    };

    return (
        <>
            <div id="signup" className="mt-4 mb-5">
                <div className="grid__item card card--corp ">
                    <div className=" py-4">
                        <form
                            onSubmit={handleSubmit}
                            className="d-flex flex-column  gap-4 px-4 "
                        >
                            <div className="position-relative">
                                <InputField
                                    faIcon="fa fa-envelope"
                                    onBlur={handleBlur}
                                    value={logindata?.email}
                                    type="email"
                                    name="email"
                                    placeholder="Enter Email"
                                    onChange={handleChange}
                                />
                                {isemail.error ? null : (
                                    <LabelError error={isemail?.errorMsg} />
                                )}
                            </div>

                            <div className="position-relative">
                                <InputField
                                    faIcon="fa fa-key"
                                    onBlur={handleBlur}
                                    value={logindata?.password}
                                    type="password"
                                    name="password"
                                    placeholder="Enter Password"
                                    onChange={handleChange}
                                />
                                {ispassword.error ? null : (
                                    <LabelError error={ispassword?.errorMsg} />
                                )}
                            </div>
                            <div className="w-full">
                                <ReCAPTCHA
                                    sitekey={recaptchaKey}
                                    onChange={getTokenFromCaptcha}
                                />
                            </div>

                            <div className="d-flex justify-content-center item-center w-full">
                                <button
                                    disabled={isdisable}
                                    className={clsx(
                                        'submitbtn',
                                        `${isdisable && 'buttonDisable'}`
                                    )}
                                    type="submit"
                                >
                                    LOGIN
                                </button>
                            </div>
                        </form>
                        <ForgotPassword />

                        <div className=" d-flex justify-content-center item-center">
                            <h6 className="regisH6">
                                Need to
                                <button
                                    onClick={handleSignUp}
                                    className="SignInUp"
                                >
                                    <span>sign up?</span>
                                </button>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
