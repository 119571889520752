import TableHeader from './orderHistoryTable/TableHeader'
import React from 'react'

const OrderHistory = () => {
    return (
        <>
            <TableHeader />
        </>
    )
}

export default OrderHistory
