import logo from '../assets/newlogo.png';
import favicon from '../assets/favicon.png';

const config = {
  logoPath: logo,
  venderTitle: 'Vendors',
  projectTitle: 'Encora :: Vendors',
  faviconPath: favicon,
  facebookRedirection: 'https://www.facebook.com/EncoraLLC/?view_public_for=101325278316451',
  copyrightRedirection: 'https://encora.co/terms-conditions/',
  instagramRedirection: 'https://www.instagram.com/encorainc/',
  linkedinRedirection: 'https://www.linkedin.com/company/green-grubbox-llc/',
};

export default config;