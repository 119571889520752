import axios from 'axios';
const PlaceOrderListApi = async (userId: any, token: any) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}vendor/orderingBox/${userId}/list`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      }
    }
  );
  const result = await response.data;
  return result;
};

export default PlaceOrderListApi;
