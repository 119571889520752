import React, { SyntheticEvent, useEffect, useState } from 'react'
import UserProfileApi from '../../../service/auth/UserProfileApi'
import '../Pages.scss'
import './Profile.scss'
import { addressType } from '../../../Types'
import PersonalDetails from './PersonalDetails'
import UserAddress from './UserAddress'
import GetCategoryList from '../../../service/GetCategoryList'
import UpdateProfile from '../../../service/auth/UpdateProfile'
import Table from './Table'
import ProfileImage from './ProfileImage'
import S3Upload from '../../commonfunction/S3Upload'
import Map from './Map'
import { errorToaster, successToaster } from '../../Toast'
import Loader from '../../../utils/loader/Loader'
const UserProfile = (props: any) => {
    const { isdisable, setIsDisable } = props
    const [pageSize, setPageSize] = useState<number>(10)
    const token = sessionStorage.getItem('token')
    const [loading, setLoading] = useState<boolean>(false)
    const [addressData, setAddressData] = useState<addressType>({
        zipcode: '',
        countryCode: '',
        country: '',
        stateCode: '',
        state: '',
        city: '',
        neighborhood: '',
        premise: '',
        formattedAddress: '',
        vendorCatId: '',
    })
    const [profileData, setProfileData] = useState<any>()
    const [categoryData, setCategoryData] = useState<any>()
    useEffect(() => {
        ;(async () => {
            try {
                const result = await UserProfileApi(token)
                if (result?.status) {
                    setAddressData((prev: addressType) => ({
                        ...prev,
                        zipcode: result?.data?.address?.zipcode,
                        countryCode: result?.data?.address?.countryCode,
                        country: result?.data?.address?.country,
                        stateCode: result?.data?.address?.stateCode,
                        state: result?.data?.address?.state,
                        city: result?.data?.address?.city,
                        neighborhood: result?.data?.address?.neighborhood,
                        premise: result?.data?.address?.premise,
                        formattedAddress:
                            result?.data?.address?.formattedAddress,
                        vendorCatId: result?.data?.vendorCatId,
                    }))
                    setProfileData(result?.data)
                }
            } catch (error) {
                console.log(error)
            }
        })()
        ;(async () => {
            try {
                const result = await GetCategoryList(token, pageSize)
                if (result?.status) {
                    setCategoryData(result?.data)
                }
            } catch (error) {
                console.log(error)
            }
        })()
    }, [])

    const handleAddress = (value: string) => {
        if (value.length < 1) {
            setAddressData({
                zipcode: '',
                countryCode: '',
                country: '',
                stateCode: '',
                state: '',
                city: '',
                neighborhood: '',
                premise: '',
                formattedAddress: '',
                vendorCatId: '',
            })
        }
        setAddressData((prev: addressType) => ({
            ...prev,
            formattedAddress: value,
        }))
    }
    const handleUserDetails = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        setProfileData((prev: any) => ({ ...prev, [name]: value }))
    }
    const handleSelect = (value: any) => {
        setProfileData((prev: any) => ({
            ...prev,
            vendorCatId: value?.value,
            catName: value?.label,
        }))
    }
    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        const reader = new FileReader()
        const targetFile = e?.target?.files as FileList
        reader.onloadend = async () => {
            const result = await S3Upload(targetFile[0], reader.result, token)
            if (result?.status) {
                setProfileData((prev: any) => ({
                    ...prev,
                    images: result?.data?.imageUrl,
                }))
            }
            successToaster(result.message)
        }
        reader.readAsDataURL(targetFile[0])
    }
    function handlePageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setPageSize(Number(e.target.value))
    }
    const handleSave = async () => {
        setProfileData((prev: any) => ({ ...prev, address: addressData }))
        if (addressData?.formattedAddress?.length <= 0) {
            errorToaster('Please fill the Address Details')
            setLoading(false)
            return
        }
        if (profileData?.name.length <= 0) {
            errorToaster('Please fill the Business name')
            setLoading(false)
            return
        }
        if (profileData?.mobile.length < 9) {
            errorToaster('Please fill the Mobile Number')
            setLoading(false)
            return
        }
        if (!profileData?.vendorCatId) {
            errorToaster('Please Select Vendor Category')
            setLoading(false)
            return
        }
        try {
            setLoading(true)
            const payload: any = {
                name: profileData?.name,
                contactPerson: profileData?.contactPerson,
                webLink: profileData?.webLink.trim(),
                yelpLink: profileData?.yelpLink.trim(),
                address: addressData?.formattedAddress,
                city: addressData?.city,
                state: addressData?.state,
                country: addressData?.country,
                zipcode: profileData?.address.zipcode,
                mobile: profileData?.mobile,
                email: profileData?.email,
                images: profileData?.images,
                description: profileData?.description,
                rating: profileData?.rating,
                category: profileData?.category,
                place_id: profileData?.place_id,
                latitude: profileData?.latitude,
                longitude: profileData?.longitude,
                vendorCatId: profileData.vendorCatId,
            }
            const result = await UpdateProfile(token, payload)
            sessionStorage.setItem(
                'isProfileCompleted',
                result?.data.isProfileCompleted
            )
            successToaster(result.message)
            setIsDisable(true)
            setLoading(false)
        } catch (error) {
            console.log('Error updating profile', error)
            setLoading(false)
        }
    }
    if (loading) {
        return <Loader startLoading={loading} childClsName="custom-Loader" />
    }

    return (
        <>
            {profileData ? (
                <div className="container">
                    <div className="row gutters">
                        <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                            <div className="user-card h-100">
                                <div className="Welcome_Heading">
                                    <h2>
                                        Welcome {profileData.name} (
                                        {profileData.catName})
                                    </h2>
                                </div>
                                <div className="user-card-body">
                                    <div className="account-settings">
                                        <div className="user-profile">
                                            <div className="user-img">
                                                <ProfileImage
                                                    profileImg={
                                                        profileData?.images
                                                    }
                                                    handleImageChange={
                                                        handleImageChange
                                                    }
                                                    isdisable={isdisable}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                            <div className="user-card h-100">
                                <div className="user-card-body">
                                    <PersonalDetails
                                        handleSelect={handleSelect}
                                        handleSave={handleSave}
                                        categoryData={categoryData}
                                        isdisable={isdisable}
                                        handleUserDetails={handleUserDetails}
                                        profiledata={profileData}
                                    />
                                    <UserAddress
                                        isdisable={isdisable}
                                        handleUserDetails={handleUserDetails}
                                        handleChange={handleAddress}
                                        address={addressData}
                                        profiledata={profileData}
                                    />
                                    <Map
                                        isdisable={isdisable}
                                        handleChange={handleAddress}
                                        profileData={profileData}
                                        setProfileData={setProfileData}
                                        addressData={addressData}
                                        setAddressData={setAddressData}
                                    />
                                    <Table profileData={profileData} />
                                    {!isdisable && (
                                        <div className="row gutters">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                <button
                                                    className="save-btn text-right"
                                                    onClick={() => {
                                                        handleSave()
                                                    }}
                                                    disabled={loading}
                                                >
                                                    save
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="container mt-5">
                    <div className="p-3 jumbotron rounded">
                        <span className="loader"></span>
                    </div>
                </div>
            )}
        </>
    )
}

export default UserProfile
