import {
    EmailValidation,
    MobileValidation,
    NameValidation,
    PasswordValidation,
} from '../auth/AuthFieldValidation'

const useValidationError = (
    setIsName: any,
    setIsEmail: any,
    setIsMobile: any,
    setIsPassword: any
) => {
    const validationErrorCheck = (value: string, name: string) => {
        switch (name) {
            case 'name': {
                const nvalid = NameValidation(value)
                setIsName((old: any) => ({
                    ...old,
                    errorMsg: nvalid?.errorMsg,
                    error: nvalid?.error,
                    buttonDisable: nvalid?.disableError,
                }))
                break
            }
            case 'email': {
                const evalid = EmailValidation(value)
                setIsEmail((old: any) => ({
                    ...old,
                    errorMsg: evalid?.errorMsg,
                    error: evalid?.error,
                    buttonDisable: evalid?.disableError,
                }))
                break
            }
            case 'mobile': {
                const mvalid = MobileValidation(value)
                setIsMobile((old: any) => ({
                    ...old,
                    errorMsg: mvalid?.errorMsg,
                    error: mvalid?.error,
                    buttonDisable: mvalid?.disableError,
                }))
                break
            }
            case 'password': {
                const pvalid = PasswordValidation(value)
                setIsPassword((old: any) => ({
                    ...old,
                    errorMsg: pvalid?.errorMsg,
                    error: pvalid?.error,
                    buttonDisable: pvalid?.disableError,
                }))
                break
            }
            default:
                return
        }
    }

    return validationErrorCheck
}

export default useValidationError
