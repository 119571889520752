import React, { useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { convertDate } from './TableHeader'
import { useLocation, useNavigate } from 'react-router-dom'
import OrderBoxes from './OrderBoxes'
import './orderHistoryTable.scss'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import OrderDetailsApi from '../../../service/OrderDetailsApi'
import PayOrderApi from '../../../service/PayOrderApi'
import { errorToaster, successToaster } from '../../Toast'
import OrderInfoApi from '../../../service/OrderInfoApi'
import Loader from '../../../utils/loader/Loader'
const OrderInfo = (props: any) => {
    const { dashboardInfoModal } = props
    const { state } = useLocation()
    const naviagate = useNavigate()
    const token = sessionStorage.getItem('token')
    const path = window.location.pathname.slice(1)
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [info, setinfo] = useState<any>([])

    const orderInfoData = async (state: any, token: any) => {
        setLoading(true)
        try {
            const result = await OrderInfoApi(state, token)
            if (result?.status) {
                setinfo(result.data)
                setLoading(false)
            }
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const orderInfo = () => {
        if (dashboardInfoModal) {
            orderInfoData(dashboardInfoModal._id, token)
        } else {
            orderInfoData(state, token)
        }
    }
    useEffect(() => {
        orderInfo()
    }, [dashboardInfoModal, state])

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const [orderDetailsData, setOrderDetailsData] = useState<any>([])
    const statusCheck = (status: number) => {
        switch (status) {
            case 1:
                return 'Ordered'
            case 2:
                return 'In Progress'
            case 3:
                return 'Dispatched'
            case 4:
                return 'Delivered'
            case 5:
                return 'Invoiced'
            case 6:
                return 'Paid'
        }
    }
    const cardRegExp = /^[0-9]{16}$/
    const cvcRegExp = /^\d{3}$/
    const formik: any = useFormik({
        initialValues: {
            name: '',
            expiryDate: '',
            cardNumber: '',
            cardCVV: '',
        },

        validationSchema: Yup.object({
            name: Yup.string()
                .required('Required!')
                .matches(/^[A-Za-z]+$/, 'invalid name'),
            cardNumber: Yup.string()
                .matches(cardRegExp, 'Card number is not valid')
                .required('Required!')
                .min(16, 'Card number must be 16 digits')
                .max(16, 'Card number must be 16 digits'),
            expiryDate: Yup.date()
                .required('Date is required')
                .min(new Date(), 'Date cannot be in the past'),
            cardCVV: Yup.string()
                .matches(cvcRegExp, 'Card CVC is not valid')
                .required('Required!')
                .max(3, '3 digits only'),
        }),
        onSubmit: (values: any) => {
            values.cardExpiryMonth = values.expiryDate.slice(-2)
            values.cardExpiryYear = values.expiryDate.slice(0, 4)
            const obj = { ...values, ...orderDetailsData }

            if (values) {
                const orderPay = async () => {
                    try {
                        const response: any = await PayOrderApi(obj, token)
                        if (response?.status) {
                            successToaster(response?.message)
                            orderInfo()
                            setShow(false)
                        } else {
                            errorToaster(response?.message)
                        }
                    } catch (error) {
                        console.log(error)
                    }
                }
                orderPay()
            }
        },
    })
    // API Order
    const fetchOrderDetails = async () => {
        setLoading(true)
        try {
            const result: any = await OrderDetailsApi(info._id)
            setOrderDetailsData(result?.data[0])
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    const getInvoicePDF = (url: any) => {
        return window.open(url, '_blank')
    }
    useMemo(() => {
        if (show) {
            fetchOrderDetails()
        }
    }, [show])
    if (loading) {
        return <Loader startLoading={loading} />
    }
    return (
        <>
            <div className="container mb-5 orderHistorySec">
                <div className="orderHeading">
                    {path === 'dashboard' ? null : (
                        <i
                            onClick={() => {
                                naviagate('/orderhistory')
                            }}
                            className="fa fa-chevron-left backIcon"
                            aria-hidden="true"
                        ></i>
                    )}

                    <p className="orderTitle">
                        {path == 'orderSummary' ? 'ORDER SUMMARY' : ''}
                    </p>
                </div>

                <div className="row">
                    <div className="col-md-12 col-lg-6 col-sm-12">
                        <div className="order_summary_boxshadow">
                            <div className="text-center toporderbox">
                                <h3 className="details">Order Details</h3>
                                <table className="table_format table table-bordered table-striped table_border">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <b>Order Status:</b>
                                            </td>
                                            <td>
                                                {info?.orderStatus
                                                    ? statusCheck(
                                                        info?.orderStatus
                                                    )
                                                    : '-'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <b>Ordered By:</b>
                                            </td>
                                            <td>
                                                {info?.orderBy
                                                    ? info?.orderBy
                                                    : '-'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <b>Order ID:</b>
                                            </td>
                                            <td>
                                                {info?.orderNumber
                                                    ? info?.orderNumber
                                                    : '-'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <b>Ordered On:</b>
                                            </td>
                                            <td>
                                                {info?.orderDate
                                                    ? convertDate(
                                                        info?.orderDate
                                                    )
                                                    : '-'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <b>Dispatched On:</b>
                                            </td>
                                            <td>
                                                {info?.dispatchDate
                                                    ? convertDate(
                                                        info?.dispatchDate
                                                    )
                                                    : '-'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <b>Delivered On:</b>
                                            </td>
                                            <td>
                                                {info.deliveryDate
                                                    ? convertDate(
                                                        info?.deliveryDate
                                                    )
                                                    : '-'}
                                            </td>
                                        </tr>
                                        {path != 'dashboard' ? (
                                            <tr>
                                                <td>
                                                    <b>Invoiced Status:</b>
                                                </td>
                                                <td>
                                                    {info.deliveryDate
                                                        ? `   ${convertDate(
                                                            info?.deliveryDate
                                                        )} `
                                                        : '-'}
                                                </td>
                                            </tr>
                                        ) : (
                                            ''
                                        )}
                                        {path == 'dashboard' ? (
                                            <tr>
                                                <td>
                                                    <b>Invoiced On:</b>
                                                </td>
                                                <td>
                                                    {info?.invoicePaidDate ? (
                                                        <a>
                                                            <i
                                                                className="fa fa-download invoiceDownload"
                                                                onClick={() =>
                                                                    getInvoicePDF(
                                                                        info?.invoiceUrl
                                                                    )
                                                                }
                                                                aria-hidden="true"
                                                            ></i>
                                                        </a>
                                                    ) : (
                                                        ''
                                                    )}
                                                </td>
                                            </tr>
                                        ) : (
                                            ''
                                        )}

                                        {path != 'dashboard' ? (
                                            <tr>
                                                <td>
                                                    <b>Invoiced:</b>
                                                </td>
                                                <td>
                                                    {info.invoiceNumber
                                                        ? info?.invoiceNumber
                                                        : '-'}
                                                    {info?.deliveryDate &&
                                                        path != 'dashboard' &&
                                                        !info?.invoicePaidDate ? (
                                                        <Button
                                                            className={clsx(
                                                                'PayButton'
                                                            )}
                                                            onClick={handleShow}
                                                        >
                                                            PAY
                                                        </Button>
                                                    ) : (
                                                        ''
                                                    )}
                                                </td>
                                            </tr>
                                        ) : (
                                            ''
                                        )}

                                        <tr>
                                            <td>
                                                <b>Paid On:</b>
                                            </td>
                                            <td>
                                                {info?.invoicePaidDate
                                                    ? convertDate(
                                                        info?.invoicePaidDate
                                                    )
                                                    : '-'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <b>Total Assigned Boxes:</b>
                                            </td>
                                            <td>{info.totalBoxAssigned}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {info.orderedBoxes ? (
                        <OrderBoxes orderInfoData={info} />
                    ) : (
                        'data not found'
                    )}
                </div>
            </div>
            <Modal
                dal
                size="xl"
                show={show}
                onHide={handleClose}
                dialogClassName="modal-90w"
                className="model"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton className="placeHeader">
                    <Modal.Title className="paymentDetailHeading">
                        <h4 className="orderSec">Payment Details</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="OrderPaymentDetails">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="col-lg-12 col-sm-12">
                                        <div className="order_summary_boxshadow">
                                            <div className="text-center toporderbox">
                                                <h3>Order Details</h3>
                                                <table className="table_format table table-bordered table-striped table_border">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <b>Order Id:</b>
                                                            </td>
                                                            <td>
                                                                {orderDetailsData
                                                                    ? orderDetailsData.orderNumber
                                                                    : '-'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                    Ordered
                                                                    Value ($):
                                                                </b>
                                                            </td>
                                                            <td>
                                                                {orderDetailsData
                                                                    ? orderDetailsData.orderValue
                                                                    : '-'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                    Tax Rate(%):
                                                                </b>
                                                            </td>
                                                            <td>
                                                                {orderDetailsData
                                                                    ? orderDetailsData.taxRate
                                                                    : '-'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                    Tax
                                                                    Value($):
                                                                </b>
                                                            </td>
                                                            <td>
                                                                {orderDetailsData
                                                                    ? orderDetailsData.taxValue
                                                                    : '-'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                    Claim
                                                                    Amount($):
                                                                </b>
                                                            </td>
                                                            <td>
                                                                {orderDetailsData
                                                                    ? orderDetailsData.claimAdjustAmt
                                                                    : '-'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <b>
                                                                    Grand Total:
                                                                </b>
                                                            </td>
                                                            <td>
                                                                {orderDetailsData
                                                                    ? orderDetailsData.orderValueWithTax
                                                                    : '-'}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <form
                                        className="row g-3 CardDetailsSec order_summary_boxshadow"
                                        onSubmit={formik.handleSubmit}
                                        noValidate
                                    >
                                        <div className="col-md-6">
                                            <div className="inputdiv">
                                                <label
                                                    htmlFor="inputEmail4"
                                                    className="form-label"
                                                >
                                                    Name:
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="inputEmail4"
                                                    placeholder="Name"
                                                    name="name"
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.name}
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                />
                                                <i className="fa-solid fa-user"></i>
                                            </div>
                                            {formik.touched.name &&
                                                formik.errors.name ? (
                                                <p
                                                    style={{ color: 'red' }}
                                                    className="inputError"
                                                >
                                                    {formik?.errors?.name}
                                                </p>
                                            ) : null}
                                        </div>
                                        <div className="col-md-6">
                                            <div className="inputdiv">
                                                <label
                                                    htmlFor="inputPassword4"
                                                    className="form-label"
                                                >
                                                    Card No:
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="inputPassword4"
                                                    placeholder="Card Number"
                                                    name="cardNumber"
                                                    onBlur={formik.handleBlur}
                                                    value={
                                                        formik.values.cardNumber
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                />
                                                <i className="fa-regular fa-credit-card"></i>
                                            </div>
                                            {formik.touched.cardNumber &&
                                                formik.errors.cardNumber ? (
                                                <p
                                                    style={{ color: 'red' }}
                                                    className="inputError"
                                                >
                                                    {formik.errors.cardNumber}
                                                </p>
                                            ) : null}
                                        </div>
                                        <div className="col-md-6">
                                            <div className="inputdiv">
                                                <label
                                                    htmlFor="inputEmail4"
                                                    className="form-label"
                                                >
                                                    Expiry Date:
                                                </label>
                                                <input
                                                    type="month"
                                                    className="form-control"
                                                    id="inputEmail4"
                                                    placeholder="Expiry Date"
                                                    name="expiryDate"
                                                    onBlur={formik.handleBlur}
                                                    value={
                                                        formik.values.expiryDate
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                />
                                                <i className="fa-solid fa-calendar-days"></i>
                                            </div>
                                            {formik.touched.expiryDate &&
                                                formik.errors.expiryDate ? (
                                                <p
                                                    style={{ color: 'red' }}
                                                    className="inputError"
                                                >
                                                    {formik.errors.expiryDate}
                                                </p>
                                            ) : null}
                                        </div>
                                        <div className="col-md-6">
                                            <div className="inputdiv">
                                                <label
                                                    htmlFor="inputPassword4"
                                                    className="form-label"
                                                >
                                                    CVC:
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="inputPassword4"
                                                    placeholder="CVC"
                                                    name="cardCVV"
                                                    onBlur={formik.handleBlur}
                                                    value={
                                                        formik.values.cardCVV
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                />
                                                <i className="fa-regular fa-credit-card"></i>
                                            </div>
                                            {formik.touched.cardCVV &&
                                                formik.errors.cardCVV ? (
                                                <p
                                                    style={{ color: 'red' }}
                                                    className="inputError"
                                                >
                                                    {formik.errors.cardCVV}
                                                </p>
                                            ) : null}
                                        </div>
                                        <div className="col-md-12">
                                            <div className="text-end">
                                                <button
                                                    type="submit"
                                                    className="SubmitBtn"
                                                >
                                                    SUBMIT
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default OrderInfo
