import clsx from 'clsx'
import { useEffect, useState } from 'react'
import PlaceOrderListApi from '../../../service/PlaceOrderListApi'
import PlaceOrderDetails from './PlaceOrderDetails'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Place from '../../../service/PlaceOrder'
import './PlaceOrder.scss'
import { errorToaster, successToaster } from '../../Toast'
import { useNavigate } from 'react-router-dom'
type PlaceOrderType = {
    className: string
}
const PlaceOrder: React.FC<PlaceOrderType> = ({ className }) => {
    const [show, setShow] = useState(false)
    const [placeOrderData, setPlaceOrderData] = useState({
        orderDetailsData: [],
        orderQuantity: [],
    })
    const userId = sessionStorage.getItem('userId')
    const token = sessionStorage.getItem('token')
    const isProfileCompleted = JSON.parse(
        sessionStorage.getItem('isProfileCompleted') || '{}'
    )
    const navigate = useNavigate()
    const getQuantity = (input: number) => {
        let index = 1
        let tempArr = []
        while (index <= input) {
            tempArr.push({ label: String(50 * index), value: 50 * index })
            index++
        }
        return tempArr
    }
    useEffect(() => {
        ;(async () => {
            try {
                const result = await PlaceOrderListApi(userId, token)
                if (result?.status) {
                    const quantity = getQuantity(40)
                    setPlaceOrderData((prev: any) => ({
                        ...prev,
                        orderDetailsData: result?.data,
                        orderQuantity: quantity,
                    }))
                } else {
                    console.log('error first', result?.message)
                    // errorToaster(result?.message);
                }
            } catch (error) {
                console.log(error)
            }
        })()
    }, [show])

    const handleClick = async () => {
        var requestData = placeOrderData?.orderDetailsData
            .filter(function (el: any) {
                return el.quantity
            })
            .map(function (el: any) {
                return {
                    typeId: el._id,
                    quantity: el.quantity?.value.toString(),
                }
            })
        if (requestData && requestData.length == 0) {
            errorToaster('Invalid order quantity')
            return false
        } else {
            try {
                const result = await Place({ order: requestData }, token)
                if (result?.status) {
                    successToaster(result?.message)
                    handleClose()
                } else {
                    errorToaster(result?.message)
                    handleClose()
                }
            } catch (error: any) {
                errorToaster(error?.message)
                console.log(error)
            }
        }
    }
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    return (
        <>
            <Button
                className={clsx('place-order-button', className)}
                onClick={handleShow}
            >
                Place Order
            </Button>

            <Modal
                size="lg"
                show={show}
                onHide={handleClose}
                className="model"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton className="placeHeader">
                    <Modal.Title>
                        <h4 className="orderSec">Orders</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isProfileCompleted ? (
                        <PlaceOrderDetails
                            placeOrderDetail={placeOrderData}
                            setPlaceOrderData={setPlaceOrderData}
                            handleClick={handleClick}
                        />
                    ) : (
                        <div className="detail-para">
                            <p>Please complete your profile first</p>
                            <span
                                className="profile-Click"
                                onClick={() => {
                                    navigate('/profile')
                                    handleClose()
                                }}
                            >
                                Click here&nbsp;
                            </span>
                            to go to profile or&nbsp;
                            <span
                                className="profile-Click"
                                onClick={() => {
                                    handleClose()
                                }}
                            >
                                Cancel Order
                            </span>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PlaceOrder
