import axios from 'axios';
const DashBoardApi = async () => {
    const token = sessionStorage.getItem('token');
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}web/vendor/dashBoard`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
        },
    });
    const result = await response.data;
    return result;
};

export default DashBoardApi;
