import React from 'react';
import Select from 'react-select';
const PersonalDetails = (props: any) => {
    const {
        handleUserDetails,
        profiledata,
        isdisable,
        categoryData,
        handleSelect,
    } = props;
    return (
        <>
            <div className="row gutters">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <h6 className="mb-2 heading-text-color">
                        Personal Details
                    </h6>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-2">
                    <div className="form-group">
                        <label>
                            <span className='mandatory-field'>*</span>
                            Business Name : </label>
                        <input
                            type="text"
                            className="form-control"
                            disabled={isdisable}
                            name="name"
                            value={profiledata?.name}
                            onChange={handleUserDetails}
                            placeholder="Business Name"
                        />
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-2">
                    <div className="form-group">
                        <label>Business Location : </label>
                        <input
                            type="text"
                            className="form-control"
                            disabled
                            name="locationName"
                            value={profiledata?.locationName}
                            onChange={handleUserDetails}
                            placeholder="Business Location"
                        />
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-2">
                    <div className="form-group">
                        <label>Contact Person Name : </label>
                        <input
                            type="text"
                            className="form-control"
                            disabled={isdisable}
                            name="contactPerson"
                            value={profiledata?.contactPerson}
                            onChange={handleUserDetails}
                            placeholder="Contact Person Name"
                        />
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-2">
                    <div className="form-group">
                        <label>
                        <span className='mandatory-field'>*</span>
                            Mobile No : </label>
                        <input
                            type="text"
                            className="form-control"
                            disabled={isdisable}
                            name="mobile"
                            value={profiledata?.mobile}
                            onChange={handleUserDetails}
                            placeholder="Mobile No"
                        />
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-2">
                    <div className="form-group">
                        <label>Email Address</label>
                        <input
                            type="email"
                            className="form-control"
                            name="email"
                            disabled={true}
                            value={profiledata?.email}
                            onChange={handleUserDetails}
                            placeholder="Email Address"
                        />
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-2">
                    <div className="form-group">
                        <label>Description</label>
                        <input
                            type="text"
                            className="form-control"
                            name="description"
                            disabled={isdisable}
                            value={profiledata?.description}
                            onChange={handleUserDetails}
                            placeholder="Description"
                        />
                    </div>
                </div>
                {/* {!isdisable && ( */}
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-2">
                        <div className="form-group">
                        <span className='mandatory-field'>*</span>
                            <label>Select Category</label>

                            <Select
                                name="catName"
                                isDisabled={isdisable}
                                required
                                value={profiledata?.catName}
                                onChange={handleSelect}
                                options={categoryData?.map((e: any) => ({
                                    value: e._id,
                                    label: e.name,
                                }))}
                                placeholder={profiledata?.catName}
                            />
                        </div>
                    </div>
                {/* )} */}
            </div>
        </>
    );
};

export default PersonalDetails;
