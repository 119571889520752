export const EmailValidation = (email: string) => {
    //eslint-disable-next-line
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (email.length === 0) {
        return { errorMsg: 'Required', error: false, disableError: 'disable' };
    } else if (!(!email || regex.test(email) === false)) {
        return { errorMsg: '', error: true, disableError: 'enable' };
    } else {
        return {
            errorMsg: 'Please Enter a Valid Email',
            error: false,
            disableError: 'disable',
        };
    }
};

export const MobileValidation = (mobile: string) => {
    //eslint-disable-next-line
    const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
    if (mobile.length === 0) {
        return { errorMsg: 'Required', error: false, disableError: 'disable' };
    } else if (mobile.length === 10) {
        return { errorMsg: '', error: true, disableError: 'enable' };
    } else if (mobile.length > 10 || mobile.length < 10) {
        return {
            errorMsg: 'Please Enter a Valid Moblie Number',
            error: false,
            disableError: 'disable',
        };
    } else if (!(!mobile || regex.test(mobile) === false)) {
        return { errorMsg: '', error: true, disableError: 'enable' };
    } else {
        return {
            errorMsg: 'Please Enter a Valid Moblie Number',
            error: false,
            disableError: 'disable',
        };
    }
};

export const NameValidation = (name: string) => {
    if (name.length === 0) {
        return { errorMsg: 'Required', error: false, disableError: 'disable' };
    } else {
        return { errorMsg: '', error: true, disableError: 'enable' };
    }
};

export const PasswordValidation = (password: string) => {
    if (password.length === 0) {
        return { errorMsg: 'Required', error: false, disableError: 'disable' };
    } else if (password.length >= 6) {
        return { errorMsg: '', error: true, disableError: 'enable' };
    } else {
        return {
            errorMsg: 'Password should contain atleast 6 characters',
            error: false,
            disableError: 'disable',
        };
    }
};
