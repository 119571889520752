import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import config from '../../../../configs/config'
import { RewardInfo } from '../../../../Types.js'
import '../Slider/RewardInfoModal.style.scss'
import QrCode from './QrCode'

function RewardInfoModal({ show, setShow, data }: RewardInfo) {
    let mapData = data[0].rewardInfo[0].vendors
    const [QrDetails, setQrDetails] = useState<any>({
        show: false,
        rewardDetails: '',
        rewardQR: '',
    })

    return (
        <>
            <Modal
                size="xl"
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header className="Main_head" closeButton>
                    <Modal.Title
                        id="example-modal-sizes-title-lg"
                        className="modalTitle"
                    >
                        <h2>Reward Detail</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="Details_Section">
                            <img
                                className="firstImage"
                                src={
                                    data[0].rewardInfo[0].image
                                        ? data[0].rewardInfo[0].image
                                        : 'not found'
                                }
                            />
                            <div className="detailRow">
                                <h5>Sponsor Type</h5>
                                <p>
                                    {data[0].rewardInfo[0].type == 1
                                        ? 'Vendor'
                                        : 'Service provider'}
                                </p>
                                <h5>Title</h5>
                                <p className="demo">
                                    {data[0].rewardInfo[0].title}
                                </p>
                                <h5>Desciption</h5>
                                <p className="demo">
                                    {data[0].rewardInfo[0].description}
                                </p>
                                <h5>
                                    No. of points on each succesful checkout
                                </h5>
                                <p className="demo">
                                    {data[0].rewardInfo[0].rewardPoints}
                                </p>
                            </div>
                        </div>
                        <div className="Restaurants_Sestion">
                            <div className="container col-sm-12 mt-5">
                                <div className="table-responsive RewardDetail">
                                    <h5>{config.venderTitle}</h5>
                                    <hr />
                                    <table className="table">
                                        <thead className="table-header">
                                            {/* <tr> */}
                                            <th className="table-header">
                                                Pic
                                            </th>
                                            <th className="table-header">
                                                Name
                                            </th>
                                            {/* </tr> */}
                                        </thead>
                                        <tbody>
                                            {mapData?.map(
                                                (item: any, index: any) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="rewardPic">
                                                                <img
                                                                    className="Demo"
                                                                    src={
                                                                        item.image
                                                                            ? item.image
                                                                            : 'not found'
                                                                    }
                                                                />
                                                            </td>
                                                            <td className="vendor_Name">
                                                                {item.name}
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="table-responsive RewardDetail">
                                    <h5>Benefits</h5>
                                    <hr />
                                    <table className="table">
                                        <thead className="table-header">
                                            <tr>
                                                <th className="table-header">
                                                    Title
                                                </th>
                                                <th className="table-header">
                                                    Type
                                                </th>
                                                <th className="table-header">
                                                    Points
                                                </th>
                                                <th className="table-header">
                                                    Value
                                                </th>
                                                <th className="table-header">
                                                    QR-code
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data[0]?.rewardBenefitList?.map(
                                                (item: any, index: any) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="rewardPic">
                                                                <p>
                                                                    {
                                                                        item?.benefitTitle
                                                                    }
                                                                </p>
                                                            </td>
                                                            <td>
                                                                {item?.type == 1
                                                                    ? 'Cash'
                                                                    : 'Merchendise'}
                                                            </td>
                                                            <td>
                                                                {item?.points}
                                                            </td>
                                                            <td>
                                                                $
                                                                {
                                                                    item?.benefitValue
                                                                }
                                                            </td>
                                                            <td>
                                                                <button
                                                                    onClick={() => {
                                                                        setShow(
                                                                            false
                                                                        )
                                                                        setQrDetails(
                                                                            {
                                                                                ...QrDetails,
                                                                                show: true,
                                                                                rewardDetails:
                                                                                    item?.benefitTitle,
                                                                                buisnessName:
                                                                                    data[0]
                                                                                        .rewardInfo[0]
                                                                                        .vendors[0]
                                                                                        .name,
                                                                                rewardQR:
                                                                                    item?.rewardQr,
                                                                            }
                                                                        )
                                                                    }}
                                                                    className="btn btn-success order-info"
                                                                >
                                                                    &nbsp;View
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <QrCode
                showQR={QrDetails.show}
                setShowQR={setQrDetails}
                data={QrDetails}
            />
        </>
    )
}

export default RewardInfoModal
