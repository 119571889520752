import React, { useState } from 'react'
import config from '../../configs/config'
import { AuthContext } from '../context/UserContext'
import './auth.css'
import Login from './Login'
import Registration from './Registration'

const AuthLayout = () => {
    const token = sessionStorage.getItem('token')
    const [authlink, setAuthLink] = useState({
        AuthLink: '',
        Token: token,
    })
    return (    
        <>
            <AuthContext.Provider
                value={{
                    authlink,
                    setAuthLink,
                }}
            >
                <div className="herovander w w--full hero_memberpage bgvenders minheights">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6 "></div>
                            <div className="col-md-4 col-md-offset-1 topbannerformsvendors">
                                <h1 className="pagehead_title">
                                    {config.venderTitle} Portal
                                </h1>
                                <div className="tab-content">
                                    {authlink?.AuthLink === 'Registration' ? (
                                        <Registration />
                                    ) : (
                                        <Login />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AuthContext.Provider>
        </>
    )
}

export default AuthLayout
