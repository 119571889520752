import React, { useEffect, useState } from 'react'
import LogoCrop75 from '../../assets/logo_crop75.png'
import DashBoardApi from '../../service/DashBoardApi'
import { dashboardData } from './Constant'
import OrderHistory from './OrderHistory'
import { DashboardType } from '../../Types'
const Dashboard = () => {
    const [dashboard, setDashboard] = useState<DashboardType>({
        count: [],
        data: [],
        vendorName: '',
    })
    useEffect(() => {
        ;(async () => {
            try {
                const result = await DashBoardApi()
                if (result?.status) {
                    setDashboard((prev: DashboardType) => ({
                        ...prev,
                        count: [
                            result?.succesCount,
                            result?.pendingCount,
                            result?.boxesCount,
                        ],
                        vendorName: result?.vendorName,
                        data: result?.data,
                    }))
                }
            } catch (error) {
                console.log(error)
            }
        })()
    }, [])
    return (
        <>
            {dashboard?.vendorName?.length > 0 ? (
                <>
                    <div className="container mt-5">
                        <div className="welcome_logo mb-5">
                            <img
                                className="welcome_logo"
                                src={LogoCrop75}
                                alt="dashboard"
                            />
                            <h2 className="heading--main welcome_heading">
                                Welcome{' '}
                                <span className="text_color">
                                    {dashboard?.vendorName}
                                </span>
                            </h2>
                        </div>
                        <div className="row">
                            {dashboardData?.map((item, index) => {
                                return (
                                    <>
                                        <div
                                            className="col-sm-12 col-md-4"
                                            key={index}
                                        >
                                            <div className="card dashboard-card">
                                                <div className="orderCard d-flex flex-column justify-content-center gap-2 align-items-center mt-2 mb-2">
                                                    <i
                                                        className={`${item?.faIcon} dashboard-icon`}
                                                        aria-hidden="true"
                                                    ></i>
                                                    <h4 className="dashboard-heading">
                                                        {item?.title}
                                                    </h4>
                                                </div>

                                                <div className="card-body dashboard-body">
                                                    <h5 className="card-title d-flex justify-content-center dashboard-title">
                                                        {
                                                            dashboard?.count[
                                                                index
                                                            ]
                                                        }
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                    <OrderHistory />
                </>
            ) : (
                <div className="container mt-5">
                    <div className="p-3 jumbotron rounded">
                        <span className="loader"></span>
                    </div>
                </div>
            )}
        </>
    )
}

export default Dashboard
