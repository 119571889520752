import React, { useEffect, useState } from 'react'
import ClaimsApi from '../../service/ClaimsApi'
import moment from 'moment'
import './Pages.scss'
import Pagination from 'react-js-pagination'
import Loader from '../../utils/loader/Loader'

const Claims = () => {
    const [claimsData, setClaimsData] = useState<any>()
    const [total, setTotal] = useState<number>(0)
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [pageSize, setPageSize] = useState<number>(10)
    const [loading, setLoading] = useState<any>(false)
    const userID = sessionStorage.getItem('userId')
    const [totalPending, setTotalPending] = useState<number>()
    const fetchClaimsData = async () => {
        try {
            setLoading(true)
            const result = await ClaimsApi(currentPage, pageSize, userID)
            setClaimsData(result?.data)
            setTotalPending(result.pendingAdjustment)
            setTotal(result.totalRecords)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }
    function handlePageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setPageSize(Number(e.target.value))
    }
    useEffect(() => {
        fetchClaimsData()
    }, [currentPage, pageSize])

    if (loading) {
        return <Loader startLoading={loading} />
    }

    return (
        <>
            {claimsData && Object.keys(claimsData).length > 0 ? (
                <>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="First_Row">
                                    <div className="Claims_Heading">
                                        <h3>Claims</h3>
                                    </div>
                                    <div className="Total_Pending_Details">
                                        <p>
                                            Total Pending For Adjustment -
                                            {totalPending}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container col-sm-12 mt-4">
                            <div className="table-responsive containerDetail">
                                <table className="table">
                                    <thead className="table-header">
                                        <tr>
                                            <th className="table-header">
                                                Claim code
                                            </th>
                                            <th className="table-header">
                                                Claim Type
                                            </th>
                                            <th className="table-header">
                                                Claim cash
                                            </th>
                                            <th className="table-header">
                                                Claim Date
                                            </th>
                                            <th className="table-header">
                                                User name
                                            </th>

                                            <th className="table-header">
                                                Reward Pic
                                            </th>

                                            <th className="table-header">
                                                Sponsor Type
                                            </th>

                                            <th className="table-header">
                                                Sponsor Status
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {claimsData?.map(
                                            (item: any, index: any) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="rewardPic">
                                                            {
                                                                item
                                                                    .claimInfo[0]
                                                                    .uniqueId
                                                            }
                                                        </td>
                                                        <td>
                                                            {item.claimInfo[0]
                                                                .earnedType == 1
                                                                ? 'Cash'
                                                                : 'Goods'}
                                                        </td>
                                                        <td>
                                                            {item.claimInfo[0]
                                                                .earnedValue
                                                                ? item
                                                                      .claimInfo[0]
                                                                      .earnedValue
                                                                : '-----'}
                                                        </td>
                                                        <td>
                                                            {moment(
                                                                item
                                                                    .claimInfo[0]
                                                                    .redeemDate
                                                            ).format(
                                                                'MMM-DD-YYYY'
                                                            )}
                                                        </td>
                                                        <td>
                                                            {
                                                                item
                                                                    .userDetails[0]
                                                                    .name
                                                            }
                                                        </td>
                                                        <td className="rewardPic">
                                                            {' '}
                                                            <img
                                                                className="firstImage"
                                                                src={
                                                                    item
                                                                        .rewardInfo[0]
                                                                        .image
                                                                        ? item
                                                                              .rewardInfo[0]
                                                                              .image
                                                                        : 'not found'
                                                                }
                                                            />
                                                        </td>
                                                        <td>
                                                            {item.rewardInfo[0]
                                                                .type == 1
                                                                ? 'Vendor'
                                                                : 'Service Provider'}
                                                        </td>
                                                        <td>
                                                            {item.rewardInfo[0]
                                                                .type == 1 ? (
                                                                <button className="btn btn-success order-info">
                                                                    <i className="fa fa-info-circle"></i>
                                                                    Completed
                                                                </button>
                                                            ) : item
                                                                  .rewardInfo[0]
                                                                  .isAdjusted ==
                                                              false ? (
                                                                <button>
                                                                    Pending
                                                                </button>
                                                            ) : (
                                                                <button className="btn btn-success order-info">
                                                                    Completed
                                                                </button>
                                                            )}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="table__pagination__container">
                        {total > pageSize && (
                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={pageSize}
                                totalItemsCount={total}
                                onChange={(page: any) => {
                                    setCurrentPage(page)
                                }}
                            />
                        )}
                        {claimsData && Object.keys(claimsData).length > 10 && (
                            <select
                                className="pagination__select"
                                value={pageSize}
                                onChange={handlePageSizeChange}
                            >
                                {[10, 20, 30].map((size) => (
                                    <option
                                        className="dropdwon_Option"
                                        key={size}
                                        value={size}
                                    >
                                        Show {size}
                                    </option>
                                ))}
                            </select>
                        )}
                    </div>
                </>
            ) : (
                <div className="container mt-5">
                    <div className="jumbotron">
                        <h2 className="NodataFound">No claims available</h2>
                    </div>
                </div>
            )}
        </>
    )
}

export default Claims
