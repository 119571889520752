import axios from 'axios';
const GetCategoryList = async (token: any, pageSize: number) => {
    const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/vendorCat/list?currentPage=1&pageSize=${pageSize}`,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: token,
            },
        }
    );
    const result = await response.data;
    return result;
};

export default GetCategoryList;
