import React, {
    useState,
    useEffect,
    ChangeEvent,
    SyntheticEvent,
    useContext,
} from 'react';
import RegistrationApi from '../../service/auth/RegistrationApi';
import InputField, { ValueType } from '../Fields/InputField';
import { RegisType, validationType } from '../../Types';
import { errorToaster, successToaster } from '../Toast';
import LabelError from './LabelError';
import { AuthContext } from '../context/UserContext';
import clsx from 'clsx';
import useValidationError from '../customHook/useValidationError';
import { EnableDisableRegistration } from './EnableDisable';
import GetLocationListApi from '../../service/GetLocationList';
const Registration = () => {
    const [regisData, setRegisData] = useState<RegisType>({
        name: '',
        email: '',
        password: '',
        mobile: '',
        locationId: '',
    });
    const initialValue = {
        errorMsg: '',
        error: true,
        buttonDisable: '',
    };
    const [isdisable, setIsDisable] = useState(true);
    const [optionData, setOptionData] = useState<any>([]);
    const [isemail, setIsEmail] = useState<validationType>(initialValue);
    const [ismobile, setIsMobile] = useState<validationType>(initialValue);
    const [ispassword, setIsPassword] = useState<validationType>(initialValue);
    const [isname, setIsName] = useState<validationType>(initialValue);
    const validationErrorCheck = useValidationError(
        setIsName,
        setIsEmail,
        setIsMobile,
        setIsPassword
    );
    const { setAuthLink } = useContext(AuthContext);
    const handleChange = (e: SyntheticEvent, v: ValueType) => {
        const { name, value } = v;
        switch (name) {
            case 'name':
                validationErrorCheck(value, name);
                break;
            case 'email':
                validationErrorCheck(value, name);
                break;
            case 'mobile':
                validationErrorCheck(value, name);
                break;
            case 'password':
                validationErrorCheck(value, name);
                break;
            default:
                return;
        }
        setRegisData((oldValue: RegisType) => ({ ...oldValue, [name]: value }));
    };
    useEffect(() => {
        const check = EnableDisableRegistration(
            isemail.buttonDisable,
            isname.buttonDisable,
            ismobile.buttonDisable,
            ispassword.buttonDisable,
            regisData?.locationId?.length
        );
        setIsDisable(check);
    }, [isemail, isname, ispassword, ismobile, regisData]);

    const handleBlur = (name: string, value: string) => {
        //use onblur  for validation error
    };

    const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        const { value } = e.target;
        setRegisData((oldValue: RegisType) => ({
            ...oldValue,
            locationId: value,
        }));
    };

    useEffect(() => {
        (async () => {
            try {
                const result = await GetLocationListApi();
                setOptionData((prev: any) => [...prev, result?.data]);
            }
            catch (error) {
                console.log(error)
            }
        })();
    }, []);

    const handleSignIn = () => {
        setAuthLink((prev: any) => ({ ...prev, AuthLink: 'Login' }));
    };
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const result = await RegistrationApi(regisData);
        if (result?.status) {
            setAuthLink((prev: any) => ({ ...prev, AuthLink: 'Login' }));
            successToaster(result?.message);
        } else {
            errorToaster(result?.message);
        }
    };

    return (
        <>
            <div id="signup" className="mt-4 mb-5">
                <div className="grid__item card card--corp ">
                    <div className="py-4">
                        <form
                            onSubmit={handleSubmit}
                            className="d-flex flex-column gap-4 px-4 "
                        >
                            <div className="position-relative">
                                <InputField
                                    faIcon="fa fa-user"
                                    value={regisData?.name}
                                    type="text"
                                    name="name"
                                    placeholder="Enter Business Name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {isname.error ? null : (
                                    <LabelError error={isname?.errorMsg} />
                                )}
                            </div>
                            <div className="position-relative">
                                <InputField
                                    faIcon="fa fa-envelope"
                                    onBlur={handleBlur}
                                    value={regisData?.email}
                                    type="email"
                                    name="email"
                                    placeholder="Enter Email"
                                    onChange={handleChange}
                                />
                                {isemail.error ? null : (
                                    <LabelError error={isemail?.errorMsg} />
                                )}
                            </div>
                            <div className="position-relative">
                                <InputField
                                    faIcon="fa fa-mobile"
                                    onBlur={handleBlur}
                                    value={regisData?.mobile}
                                    type="phone"
                                    name="mobile"
                                    placeholder="Enter Mobile Number"
                                    onChange={handleChange}
                                />
                                {ismobile.error ? null : (
                                    <LabelError error={ismobile?.errorMsg} />
                                )}
                            </div>
                            <div className="position-relative">
                                <InputField
                                    faIcon="fa fa-key"
                                    onBlur={handleBlur}
                                    value={regisData?.password}
                                    type="password"
                                    name="password"
                                    placeholder="Enter Password"
                                    onChange={handleChange}
                                />
                                {ispassword.error ? null : (
                                    <LabelError error={ispassword?.errorMsg} />
                                )}
                            </div>
                            <div className="position-relative">
                                <div className="position-absolute icon">
                                    <i className="fa fa-globe" />
                                </div>
                                <select
                                    name="locationPicker"
                                    className="form-select"
                                    aria-label="Default select example"
                                    onChange={(e) => handleSelect(e)}
                                >
                                    <option selected>Select</option>
                                    {optionData[0]?.map((item: any, index: number) => (
                                        <option value={item._id} key={index}>
                                            {item?.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="d-flex justify-content-center item-center w-full">
                                <button
                                    disabled={isdisable}
                                    className={clsx(
                                        'submitbtn',
                                        `${isdisable && 'buttonDisable'}`
                                    )}
                                    type="submit"
                                >
                                    REGISTER
                                </button>
                            </div>
                        </form>
                        <div className=" d-flex justify-content-center item-center">
                            <h6 className="regisH6">
                                Already have an account to&nbsp;
                                <button
                                    onClick={handleSignIn}
                                    className="SignInUp"
                                >
                                    <span>sign in </span>
                                </button>
                                ?
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Registration;
