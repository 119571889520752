import axios from 'axios'
const PayOrderApi = async (obj: any, token: any) => {
    const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}web/vendor/order-pay`,
        obj,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: token,
            },
        }
    )
    const result = await response.data
    return result
}

export default PayOrderApi
