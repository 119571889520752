import React, { SyntheticEvent, useState } from 'react'
import InputField, { ValueType } from '../../Fields/InputField'
import '../Pages.scss'
import ChangePasswordApi from '../../../service/auth/ChangePasswordApi'
import { changePasswordType } from '../../../Types'
import { errorToaster, successToaster } from '../../Toast'
import { PasswordValidation } from '../../auth/AuthFieldValidation'
const cPassword = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
}
const ChangePassword = () => {
    const [changePassword, setChangePassword] =
        useState<changePasswordType>(cPassword)
    const handleChange = (e: SyntheticEvent, v: ValueType) => {
        const { name, value } = v
        const pvalid = PasswordValidation(value)
        setChangePassword((prevValue: changePasswordType) => ({
            ...prevValue,
            [name]: value,
        }))
    }

    const handleBlur = (name: string, value: string) => {
        console.log(name, value)
    }
    const hangleChangePassword = async (
        e: React.FormEvent<HTMLFormElement>
    ) => {
        e.preventDefault()
        if (changePassword.oldPassword == '') {
            return errorToaster('Please Fill OldPassword filed')
        }
        if (changePassword.newPassword == '') {
            return errorToaster('Please Fill NewPassword filed')
        }
        if (changePassword.confirmPassword == '') {
            return errorToaster('Please Fill ConfirmPassword filed')
        }
        const result = await ChangePasswordApi(changePassword)
        if (result?.status) {
            successToaster(result?.message)
        } else {
            errorToaster(result?.message)
        }
    }
    const clearData = () => {
        setChangePassword({
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        })
    }
    return (
        <>
            <button
                type="button"
                className="profile-btn change-password"
                data-bs-toggle="modal"
                data-bs-target="#editInformation"
            >
                <i className="fa fa-pencil" /> CHANGE PASSWORD
            </button>

            <div
                className="modal fade"
                id="editInformation"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5
                                className="modal-title"
                                id="staticBackdropLabel"
                            >
                                Change Password
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => clearData()}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={hangleChangePassword}>
                                <label className="">Old Password</label>
                                <div className="position-relative mb-3">
                                    <InputField
                                        faIcon="fa fa-key"
                                        onBlur={handleBlur}
                                        value={changePassword?.oldPassword}
                                        type="password"
                                        name="oldPassword"
                                        placeholder="Enter Old Password"
                                        onChange={handleChange}
                                    />
                                </div>
                                <label className="">New Password</label>
                                <div className="position-relative mb-3">
                                    <InputField
                                        faIcon="fa fa-key"
                                        onBlur={handleBlur}
                                        value={changePassword?.newPassword}
                                        type="password"
                                        name="newPassword"
                                        placeholder="Enter New Password"
                                        onChange={handleChange}
                                    />
                                </div>
                                <label className="">Confirm Password</label>
                                <div className="position-relative mb-3">
                                    <InputField
                                        faIcon="fa fa-key"
                                        onBlur={handleBlur}
                                        value={changePassword?.confirmPassword}
                                        type="password"
                                        name="confirmPassword"
                                        placeholder="Confirm Password"
                                        onChange={handleChange}
                                    />
                                </div>
                                <button
                                    className="change-password-changebtn mt-3"
                                    type="submit"
                                >
                                    Change Password
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChangePassword
