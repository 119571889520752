import axios from 'axios';
const ClaimsApi = async (currentPage: number, pageSize: number, userID: any) => {
    const token = sessionStorage.getItem('token');
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}web/vendor-claims?currentPage=${currentPage}&pageSize=${pageSize}&id=${userID}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
        },
    });
    const result = await response.data;
    return result;
};

export default ClaimsApi;
