import axios from 'axios';
const OrderInfoApi = async (orderId: any, token: any) => {
    const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}web/vendor/orderInfo?orderId=${orderId}`,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: token,
            },
        }
    );
    const result = await response.data;
    return result;
};

export default OrderInfoApi;
