import React, { SyntheticEvent, useState } from 'react'
import InputField, { ValueType } from '../Fields/InputField'
import { EmailValidation } from './AuthFieldValidation'
import LabelError from './LabelError'
import './auth.css'
import ForgotPasswordApi from '../../service/auth/ForgotPasswordApi'
import { validationType } from '../../Types'
import { errorToaster, successToaster } from '../Toast'
import { Modal, Button } from 'react-bootstrap'

const ForgotPassword = () => {
    const [isDisable, setIsDisable] = useState(false)
    const [isemail, setIsEmail] = useState<validationType>({
        errorMsg: '',
        error: true,
        buttonDisable: '',
    })
    const [email, setEmail] = useState<any>({
        email: '',
    })
    const [isShow, setIsShow] = useState(false)

    const handleChange = (e: SyntheticEvent, v: ValueType) => {
        const { name, value } = v
        if (name === 'email') {
            const evalid = EmailValidation(value)
            setIsEmail((old: any) => ({
                ...old,
                errorMsg: evalid?.errorMsg,
                error: evalid?.error,
                buttonDisable: evalid?.disableError,
            }))
        }
        setEmail((oldValue: any) => ({ ...oldValue, [name]: value }))
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsDisable(true)
        const result = await ForgotPasswordApi(email)
        if (result?.status==1) {
            console.log("resutl", result)
            successToaster(result?.message)
            setIsShow(false)
            setIsDisable(false)
            setEmail({
                email: '',
            })
        } else {
            // setIsShow(false)
            errorToaster(result?.message)
            setIsDisable(false)
            setEmail({
                email: '',
            })
        }
    }

    const handleBlur = (name: string, value: string) => {
        console.log(name, value)
    }
    const btnValidator = () =>{
        return email.email === '' || isDisable || isemail?.errorMsg.length > 0 
    }

    return (
        <>
            <button
                type="button"
                className="loginforgot"
                onClick={() => setIsShow(true)}
            >
                Forgot your password?
            </button>
            <Modal
                show={isShow}
                onHide={() => setIsShow(false)}
                backdrop="static"
                keyboard={false}
                top
            >
                <Modal.Header closeButton className="modalHeader">
                    <Modal.Title className="modalHeading">
                        Forgot password
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column px-3 gap-3 align-items-center">
                        <p className="AddresFiled">
                            Please enter your Email Address
                        </p>
                        <div className="w-100">
                            <form onSubmit={handleSubmit}>
                                <div className="position-relative text-muted">
                                    <InputField
                                        faIcon="fa fa-envelope"
                                        onBlur={handleBlur}
                                        value={email?.email}
                                        type="email"
                                        name="email"
                                        placeholder="Enter Email"
                                        onChange={handleChange}
                                    />
                                </div>
                                {isemail.error ? null : (
                                    <LabelError error={isemail?.errorMsg} />
                                )}
                                <Button
                                    disabled={btnValidator()}
                                    className={`submitbtn mt-3 ${
                                        email.email === '' ? 'btndisable' : ''
                                    }`}
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ForgotPassword
