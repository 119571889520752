export const dashboardData = [
    {
        title: 'SUCCESS ORDERS',
        faIcon: 'fa fa-first-order',
    },
    {
        title: 'PENDING ORDERS',
        faIcon: 'fa fa-spinner',
    },
    {
        title: 'INVENTORY WITH YOU',
        faIcon: 'fa fa-paper-plane-o',
    },
];
