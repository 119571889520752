import React, { useState, useEffect } from 'react'
import RewardApi from '../../../service/RewardApi'
import '../Reward/Rewards.style.scss'
import moment from 'moment'
import Pagination from 'react-js-pagination'
import RewardInfoModal from './Slider/RewardInfoModal'
import config from '../../../configs/config'
import Loader from '../../../utils/loader/Loader'

const Rewards = () => {
    const [total, setTotal] = useState<number>(0)
    const [rewardData, setRewardData] = useState<any>([])
    const [show, setshow] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [pageSize, setPageSize] = useState<number>(10)
    const [loading, setLoading] = useState<boolean>(false)

    const userID = sessionStorage.getItem('userId')
    const token = sessionStorage.getItem('token')

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await RewardApi(userID, token, pageSize, currentPage)
            if (result?.status) {
                setLoading(false)
                setRewardData(result.data)
                setTotal(result.totalRecords)
            }
        } catch (error) {
            console.log(error)
        }
    }
    function handlePageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setPageSize(Number(e.target.value))
    }

    useEffect(() => {
        fetchData()
    }, [currentPage, pageSize])

    if (loading) {
        return <Loader startLoading={loading} />
    }

    return (
        <>
            {rewardData.length > 0 ? (
                <>
                    <div className="container col-sm-12 mt-5">
                        <div className="Reward_heading">
                            <h3>Rewards</h3>
                        </div>
                        <div className="table-responsive containerDetail ">
                            <table className="table">
                                <thead className="table-header">
                                    <tr>
                                        <th className="table-header">
                                            Reward Pic
                                        </th>
                                        <th className="table-header">
                                            Reward Title
                                        </th>
                                        <th className="table-header">
                                            Sponsor type
                                        </th>
                                        <th className="table-header">
                                            Points{' '}
                                        </th>
                                        <th className="table-header">
                                            Created Date
                                        </th>

                                        <th className="table-header">
                                            {config.venderTitle}
                                        </th>

                                        <th className="table-header">
                                            Claim Count
                                        </th>

                                        <th className="table-header">
                                            View Detail
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rewardData?.map(
                                        (item: any, index: any) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="rewardPic">
                                                        <img
                                                            className="firstImage"
                                                            src={
                                                                rewardData[0]
                                                                    .rewardInfo[0]
                                                                    .image
                                                                    ? rewardData[0]
                                                                          .rewardInfo[0]
                                                                          .image
                                                                    : 'not found'
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        {
                                                            item.rewardInfo[0]
                                                                .title
                                                        }
                                                    </td>
                                                    <td>
                                                        {item.rewardInfo[0]
                                                            .type == 1
                                                            ? 'Vendor'
                                                            : 'Service provider'}
                                                    </td>
                                                    <td>
                                                        {
                                                            item.rewardInfo[0]
                                                                .rewardPoints
                                                        }
                                                    </td>
                                                    <td>
                                                        {' '}
                                                        {moment(
                                                            item.rewardInfo[0]
                                                                .createdAt
                                                        ).format('DD-MMM-YYYY')}
                                                    </td>
                                                    <td>
                                                        {item.rewardInfo[0]
                                                            .vendors.length > 0
                                                            ? item.rewardInfo[0]
                                                                  .vendors
                                                                  .length
                                                            : 0}
                                                    </td>
                                                    <td>{item.totalClaims}</td>
                                                    <td>
                                                        <button
                                                            onClick={() =>
                                                                setshow(true)
                                                            }
                                                            className=" btn btn-success order-info"
                                                        >
                                                            <i className="fa fa-info-circle"></i>
                                                            &nbsp;Reward Info
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className="table__pagination__container">
                            {total > pageSize && (
                                <Pagination
                                    activePage={currentPage}
                                    itemsCountPerPage={pageSize}
                                    totalItemsCount={total}
                                    onChange={(page: any) => {
                                        setCurrentPage(page)
                                    }}
                                />
                            )}
                            {rewardData.length > 10 && (
                                <select
                                    className="pagination__select"
                                    value={pageSize}
                                    onChange={handlePageSizeChange}
                                >
                                    {[10, 20, 30].map((size) => (
                                        <option
                                            className="dropdwon_Option"
                                            key={size}
                                            value={size}
                                        >
                                            Show {size}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </div>
                    </div>
                    <div className="FirstModal">
                        <RewardInfoModal
                            show={show}
                            setShow={setshow}
                            data={rewardData}
                        />
                    </div>
                </>
            ) : (
                <div className="container mt-5">
                    <div className="jumbotron">
                        <h2 className="NodataFound">No rewards available</h2>
                    </div>
                </div>
            )}
        </>
    )
}

export default Rewards
