import axios from 'axios';
const OrderHistoryApi = async (token: any, pageSize: number, currentPage: number) => {
    const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}web/vendor/orderHistory?currentPage=${currentPage}&pageSize=${pageSize}`,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: token,
            },
        }
    );
    const result = await response.data;
    return result;
};

export default OrderHistoryApi;
