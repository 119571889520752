import axios from 'axios'
const RewardApi = async (userID: any, token: any, pageSize: number, currentPage: number) => {
    const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}web/vendor-reward?currentPage=${currentPage}&pageSize=${pageSize}&id=${userID}`,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: token,
            },
        }
    )
    const result = await response.data
    return result
}

export default RewardApi
