import React from 'react';
import '../Pages.scss';
const UserAddress = (props: any) => {
    const { handleUserDetails, address, handleChange, profiledata, isdisable } =
        props;
    return (
        <>
            <div className="row gutters mb-2">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <h6 className="mt-3 mb-2 heading-text-color">Address Information</h6>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-2">
                    <div className="form-group">
                        <label>City : </label>
                        <input
                            type="text"
                            className="form-control"
                            name="city"
                            disabled
                            value={address?.formattedAddress.length>0 ? address?.city : ''}
                            onChange={handleChange}
                            placeholder="Enter Street"
                        />
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-2">
                    <div className="form-group">
                        <label>State : </label>
                        <input
                            type="text"
                            className="form-control"
                            name="state"
                            disabled
                            value={address?.formattedAddress.length>0 ? address?.state : ''}
                            onChange={handleChange}
                            placeholder="Enter Street"
                        />
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-2">
                    <div className="form-group">
                        <label>Country : </label>
                        <input
                            type="text"
                            className="form-control"
                            name="country"
                            value={address?.formattedAddress.length>0 ? address?.country : ''}
                            disabled
                            onChange={handleChange}
                            placeholder="Enter Street"
                        />
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-2">
                    <div className="form-group">
                        <label>Zipcode : </label>
                        <input
                            type="text"
                            className="form-control"
                            name="zipcode"
                            disabled
                            value={address?.formattedAddress.length>0 ? address?.zipcode : ''}
                            onChange={handleChange}
                            placeholder="Enter Street"
                        />
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-2">
                    <div className="form-group">
                        <label>Websites Url : </label>
                        <input
                            type="text"
                            className="form-control"
                            name="webLink"
                            disabled={isdisable}
                            value={profiledata?.webLink}
                            onChange={handleUserDetails}
                            placeholder="Enter Street"
                        />
                    </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-2">
                    <div className="form-group">
                        <label>Yelp url :</label>
                        <input
                            type="text"
                            className="form-control"
                            name="yelpLink"
                            disabled={isdisable}
                            value={profiledata?.yelpLink}
                            onChange={handleUserDetails}
                            placeholder="Enter Street"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserAddress;
