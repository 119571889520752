import axios from 'axios';
const UserProfileApi = async (token: any) => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}web/vendor`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
        },
    });
    const result = await response.data;
    return result;
};

export default UserProfileApi;
