import React from 'react'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import OrderInfoApi from '../../service/OrderInfoApi'
import OrderInfo from './orderHistoryTable/OrderInfo'
import Button from 'react-bootstrap/Button'
import './Pages.scss'
import Modal from 'react-bootstrap/Modal'
const DashboardOrderInfoModal = (props: any) => {
    const { id } = props
    const token = sessionStorage.getItem('token')
    const [dashboardInfoModal, setDashboardInfoModal] = useState<any>()
    const [lgShow, setLgShow] = useState(false)
    const handleClick = async (ids: any) => {
        try {
            const result = await OrderInfoApi(ids, token)
            if (result?.status) {
                setDashboardInfoModal(result?.data)
                setLgShow(true)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Button
                className=" dashboardinfomodal"
                onClick={() => handleClick(id)}
            >
                <i className="fa fa-info-circle"></i>
                &nbsp;order info
            </Button>
            <Modal
                size="xl"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton className="modalHeader">
                    <Modal.Title
                        id="example-modal-sizes-title-lg"
                        className="modaltitle"
                    >
                        Order Summary
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <OrderInfo dashboardInfoModal={dashboardInfoModal} />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DashboardOrderInfoModal
