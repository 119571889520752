import React from 'react'

const Table = (props: any) => {
    const { profileData } = props
    return (
        <>
            <div className="col-sm-12">
                <div className="infoContainer">
                    <h6 className="heading-text-color mb-3 mt-3">
                        <b>Container Information:</b>
                    </h6>
                    <div className="table-responsive containerDetail">
                        <table className="table ">
                            <thead>
                                <tr>
                                    <th>Container Name</th>
                                    <th>Container Price($)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {profileData?.boxPricing?.map(
                                    (item: any, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td>{item.name}</td>
                                                <td>{item.price}</td>
                                            </tr>
                                        )
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Table
