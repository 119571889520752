import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AuthLayout from './AuthLayout';

const PublicRoutes = () => {
    const token = sessionStorage.getItem('token');
    return token ? <Navigate to="/dashboard" /> : <AuthLayout />;
};

export default PublicRoutes;
