import React from 'react'
const OrderBoxes = (prop: any) => {
    const { orderInfoData } = prop
    let boxIds: any
    const ordersDetail = orderInfoData?.orderedBoxes.map(
        (listItems: any, i: number) => {
            if (
                listItems.assignedBoxes != null ||
                listItems.assignedBoxes != undefined ||
                listItems.assignedBoxes != '' ||
                listItems.assignedBoxes.length != 0
            ) {
                const boxIdList = listItems.assignedBoxes
                if (
                    boxIdList != undefined &&
                    boxIdList != '' &&
                    boxIdList.length != 0
                ) {
                    boxIds = boxIdList.join(',')
                } else {
                    boxIds = <p> Boxes not assigned yet </p>
                }
            }
            if (
                listItems.boxTitle != null ||
                listItems.boxTitle != undefined ||
                listItems.boxTitle != ''
            ) {
                return (
                    <div key={i} className="corporate_order_box">
                        <div className="row">
                            <div className="col-sm-3 img_position text-center firstborderbox">
                                <img src={listItems.boxIcon} alt="icon" />
                            </div>
                            <div className="col-sm-5 text-center centerborderbox">
                                <div className="ordinfo">
                                    <b>Box Name</b> {listItems.boxTitle}
                                </div>
                                <div className="ordinfo">
                                    <b>Box Id:</b>
                                    <div>{boxIds}</div>
                                </div>
                            </div>
                            <div className="col-sm-4 text-center lastborderbox">
                                <div className="ordinfo">
                                    <b>No Of Boxes:</b> {listItems.quantity}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
    return (
        <>
            <div className="col-md-12 col-lg-6 col-sm-12">
                <div className="order_summary_boxshadow">
                    <div className="text-center orderboxdetails">
                        <div className="boxHeading">
                            <h3 className="BoxDetail">Boxes Details</h3>
                        </div>
                        <div className="boxQuantity">{ordersDetail}</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderBoxes
