import axios from 'axios';
const UpdateProfile = async (token: any, obj: any) => {
    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}web/vendor`, obj, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
        },
    });
    const result = await response.data;
    return result;
};

export default UpdateProfile;
