import React from 'react';
import GoogleMapReact from 'google-map-react';
import MarKPng from '../../../assets/mark.png';
import '../profile/Profile.scss';
const MapMarker = ({ text }: any) => (
    <div className="map-marker" style={{}}>
        <img className="map-marker-img" src={MarKPng} />
        {text}
    </div>
);

const SimpleMap = (prop: any) => {
    const { lat, lng } = prop;
    const defaultProps = {
        center: {
            lat: lat,
            lng: lng,
        },
        zoom: 8,
    };

    return (
        <div className=" w-100 mt-2" style={{ height: '50vh' }}>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: 'AIzaSyCTLURh4tNtTchk12bK6KR8HJk86qph2kk',
                }}
                center={defaultProps.center}
                zoom={defaultProps.zoom}
            >
                <>
                    <MapMarker lat={lat} lng={lng} text="" />
                </>
            </GoogleMapReact>
        </div>
    );
};

export default SimpleMap;
