import React from 'react'
interface Iprops {
    startLoading: boolean
    clsName?: string
    childClsName?: string
}
const Loader: React.FC<Iprops> = ({ startLoading, clsName, childClsName }) => {
    return (
        <div className={`container mt-5 ${clsName}`}>
            {startLoading && (
                <div className={`p-3 jumbotron rounded ${childClsName}`}>
                    <span className="loader"></span>
                </div>
            )}
        </div>
    )
}

export default Loader
