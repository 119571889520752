import axios from 'axios';
const GetLocationListApi = async () => {
    const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/getLocationList?currentPage=1&pageSize=1000`
    );
    const result = await response.data;
    return result;
};

export default GetLocationListApi;
