import "./PlaceOrder.scss";
import Select from "react-select";
const PlaceOrderDetails = (prop: any) => {
    const { placeOrderDetail, setPlaceOrderData, handleClick } = prop;
    const token = sessionStorage.getItem("token");
    const handleSelect = (value: any, i: number) => {
        setPlaceOrderData((prev: any) => ({
            ...prev,
            orderDetailsData: placeOrderDetail?.orderDetailsData.map(
                (item: any) => {
                    if (i === item?._id) {
                        return { ...item, quantity: value };
                    }
                    return item;
                }
            ),
        }));
    };

    return (
        <>
            {placeOrderDetail?.orderDetailsData?.length > 0 ? (
                <>
                    {placeOrderDetail?.orderDetailsData?.map((listItems: any, i: number) => {
                        return (
                            <div className="row detailsrow container mt-3 align-items-center" key={i}>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="selectbuybox">
                                        <img
                                            className="place-order-img"
                                            src={listItems.image}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="catergoryName">{listItems.name}</div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <Select
                                        className="selectQuantity"
                                        onChange={(e) => handleSelect(e, listItems?._id)}
                                        options={placeOrderDetail?.orderQuantity.map((e: any) => ({
                                            value: e.value,
                                            label: e.label,
                                        }))}
                                        placeholder={"Select Quantity"}
                                    />
                                </div>
                            </div>
                        )
                    })}
                    <button
                        onClick={handleClick}
                        className="place-order-submitbtn mt-3"
                        type="submit"
                    >
                        ORDER
                    </button>
                </>

            ) : (
                <div className="modal-body">
                    <div className="d-flex flex-column px-3 gap-3 align-items-center">
                        <h6>
                            Contact your admin for list ordering boxes to your category.
                        </h6>
                        <button
                            onClick={handleClick}
                            className="place-order-submitbtn mt-3"
                            type="submit"
                        >
                            ORDER
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default PlaceOrderDetails;
