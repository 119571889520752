import React from 'react';
import S3UploadFiles from '../../service/S3UploadFiles';

const S3Upload = (file: any, base64: any, token: any) => {
    const requestData = {
        contentType: file.type,
        locationType: 'operator-profile',
        image: base64,
    };
    const result = S3UploadFiles(requestData, token);
    return result;
};

export default S3Upload;
