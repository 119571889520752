import React, { useState } from 'react'
import ChangePassword from './ChangePassword'
import UserProfile from './UserProfile'

const Profile = () => {
    const [isdisable, setIsDisable] = useState<boolean>(true)
    return (
        <>
            <div className="container mt-5">
                <div className="d-flex justify-content-end gap-2">
                    {isdisable && (
                        <button
                            onClick={() => setIsDisable(false)}
                            className="profile-btn edit-information"
                        >
                            <i className="fa fa-pencil" /> EDIT INFORMATION
                        </button>
                    )}
                    <ChangePassword />
                </div>
                <UserProfile
                    isdisable={isdisable}
                    setIsDisable={setIsDisable}
                />
            </div>
        </>
    )
}

export default Profile
