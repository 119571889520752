import React, { SyntheticEvent, ChangeEvent } from 'react';
import clsx from 'clsx';
export type ValueType = {
    name: string;
    value: string;
};
type InputTypes = {
    value: string | '';
    type: string;
    name: string;
    placeholder: string;
    className?: string;
    faIcon?: string;
    onChange: (e: SyntheticEvent, value: ValueType) => void;
    onBlur: (name: string, value: string) => void;
};

const InputField: React.FC<InputTypes> = ({
    value,
    faIcon,
    type,
    name,
    placeholder,
    className,
    onChange,
    onBlur,
}) => {
    const validateChange = (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        const v = { name, value };
        onChange(e, v);
    };
    const handleBlur = () => {
        onBlur(name, value);
    };

    return (
        <>
            <div className="position-absolute icon">
                <i className={`${faIcon}`} />
            </div>
            <input
                className={clsx('commonInput', className)}
                type={type}
                name={name}
                placeholder={placeholder}
                onChange={validateChange}
                onBlur={handleBlur}
            />
        </>
    );
};

export default InputField;
