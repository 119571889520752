import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { QrcodeType } from '../../../../Types'
import QRCode from 'qrcode.react'
import '../Slider/RewardInfoModal.style.scss'
import { useReactToPrint } from 'react-to-print'

function QrCode({ showQR, setShowQR, data }: QrcodeType) {
    const userID = sessionStorage.getItem('userId')
    const componentRef = useRef(null)
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    const [qr, setQr] = useState<any>({})
    const QrcodeDetails = () => {
        const qrcodevalue = JSON.stringify({
            vendor: userID,
            qr: data.rewardQR,
        })
        setQr(qrcodevalue)
    }

    useEffect(() => {
        QrcodeDetails()
    }, [data.rewardQR])
    return (
        <>
            <Modal
                size="lg"
                show={showQR}
                onHide={() => setShowQR(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                className="QR_Modal"
                centered
            >
                <Modal.Header className="Main_head" closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <h3>Qr Code</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        className="Modal_Section printAble_Content"
                        ref={componentRef}
                    >
                        <div className="businessName">
                            <div className="firstSection">
                                <h5>Business Name:</h5>
                            </div>
                            <div className="Section">
                                <p>{data.buisnessName}</p>
                            </div>
                        </div>
                        <div className="RewardSection">
                            <br></br>
                            <div className="first">
                                <h5>Reward:</h5>
                            </div>
                            <div className="secnd">
                                <p>{data.rewardDetails}</p>
                            </div>
                        </div>
                        <div className="QR_Section">
                            <QRCode value={qr} />
                        </div>
                    </div>
                    <div className="Print_QR_Section">
                        <button onClick={handlePrint} className="printClass">
                            Print
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default QrCode
