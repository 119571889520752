import React, { useEffect } from 'react';
import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import AuthLayout from './components/auth/AuthLayout';
import Footer from './components/footer/Footer';
import Navbar from './components/header/Navbar';
import OrderHistory from './components/pages/OrderHistory';
import Claims from './components/pages/Claims';
import Rewards from './components/pages/Reward/Rewards';
import Profile from './components/pages/profile/Profile';
import Dashboard from './components/pages/Dashboard';
import PrivateRoutes from './components/auth/PrivateRoutes';
import PublicRoutes from './components/auth/PublicRoutes';
import OrderInfo from './components/pages/orderHistoryTable/OrderInfo';
import 'react-toastify/dist/ReactToastify.css';
import config from './configs/config';

function App() {
    useEffect(() => {
    const favicon:any = document.querySelector('link[rel="icon"]');
    favicon.href = config.faviconPath;
    window.document.title = config.projectTitle;
  }, []);
    return (
        <div>
            <Navbar />
            <Routes>
                <Route element={<PublicRoutes />}>
                    <Route path="/restaurants" element={<AuthLayout />} />
                </Route>
                <Route element={<PrivateRoutes />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/orderhistory" element={<OrderHistory />} />
                    <Route path="/rewards" element={<Rewards />} />
                    <Route path="/claims" element={<Claims />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/orderSummary" element={<OrderInfo />} />
                    <Route path="/*" element={<Navigate to="/dashboard" />} />
                </Route>
            </Routes>
            <Footer />
        </div>
    );
}

export default App;
