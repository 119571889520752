import React, { ReactNode, useState } from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { GeoCode } from '../../commonfunction/Geocode';
import SimpleMap from './SimpleMap';

const Map = (prop: any) => {
    const {
        profileData,
        setProfileData,
        addressData,
        setAddressData,
        handleChange,
        isdisable,
    } = prop;
    const handleSelect = (address: string) => {
        geocodeByAddress(address)
        .then((results) => {
            const placeId = results[0].place_id;
            return getLatLng(results[0])
                .then((latLng) => {
                    GeoCode(latLng?.lat, latLng?.lng, setAddressData);
                    setProfileData((prev: any) => ({
                        ...prev,
                        place_id: placeId, 
                        latitude: latLng?.lat,
                        longitude: latLng?.lng,
                        geo: { coordinates: [latLng?.lng, latLng?.lat] },
                    }));
                });
        })
        .catch((error) => console.error('Error', error));
    };
    return (
        <>
            <div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-2">
                    <div className="form-group">
                        <label>
                            <span className='mandatory-field' >*</span>
                            Address  : </label>
                    </div>
                </div>
                <PlacesAutocomplete
                    value={addressData?.formattedAddress}
                    onChange={handleChange}
                    onSelect={handleSelect}
                >
                    {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                    }) => (
                        <div>
                            <input
                                {...getInputProps({
                                    disabled: isdisable,
                                    placeholder: 'Search Places',
                                    className: 'location-search-input w-100',
                                })}
                            />
                            <div className="border border-secondary">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion, index) => {
                                    return (
                                        <div
                                            className=""
                                            {...getSuggestionItemProps(
                                                suggestion,
                                                {}
                                            )} key={index}
                                        >
                                            <span>
                                                {suggestion.description}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
                <SimpleMap
                    lng={profileData?.longitude}
                    lat={profileData?.latitude}
                />
            </div>
        </>
    );
};

export default Map;
