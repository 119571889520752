import React from 'react'
import './footer.scss'
import { NavLink } from 'react-router-dom'
import config from '../../configs/config'

const Footer = () => {
    return (
        <>
            <div className="Footer Footer__main">
                <footer className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="FooterContent">
                                <div className="FooterDetails">
                                    <div className="Footer__copyright">
                                        <NavLink
                                            target="_blank"
                                            to={config.copyrightRedirection}
                                            className="footerCopyRight"
                                        >
                                            © {new Date().getFullYear()} Encora,
                                            LLC Terms of Use and Privacy Policy
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="FooterSocialIcon">
                                    <ul className="d-flex gap-2">
                                        <li className="Footer__li">
                                            <div className="d-flex justify-content-center align-items-center footer-socialIcon Footer__sGoogle">
                                                <NavLink
                                                    to={
                                                        config.facebookRedirection
                                                    }
                                                    className="fa fa-facebook footerli"
                                                ></NavLink>
                                            </div>
                                        </li>

                                        <li className="Footer__li">
                                            <div className="d-flex justify-content-center align-items-center footer-socialIcon Footer__sGoogle">
                                                <NavLink
                                                    to={
                                                        config.instagramRedirection
                                                    }
                                                    className="fa fa-instagram footerli"
                                                ></NavLink>
                                            </div>
                                        </li>

                                        <li className="Footer__li">
                                            <div className="d-flex justify-content-center align-items-center footer-socialIcon Footer__sGoogle">
                                                <NavLink
                                                    to={
                                                        config.linkedinRedirection
                                                    }
                                                    className="fa fa-globe footerli"
                                                    target="_blank"
                                                ></NavLink>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    )
}

export default Footer
