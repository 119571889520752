import React from 'react';
import Geocode from 'react-geocode';
import { addressType } from '../../Types';

export function GeoCode(
    lat: number,
    lng: number,
    setAddressData: React.Dispatch<React.SetStateAction<addressType>>
) {
    Geocode.setApiKey('AIzaSyCTLURh4tNtTchk12bK6KR8HJk86qph2kk');
    Geocode.fromLatLng(lat.toString(), lng.toString()).then(
        (response) => {
            const address = response.results[0].formatted_address;
            let city: string, state: string, country: string, zipcode: string;
            for (
                let i = 0;
                i < response.results[0].address_components.length;
                i++
            ) {
                for (
                    let j = 0;
                    j < response.results[0].address_components[i].types.length;
                    j++
                ) {
                    switch (
                        response.results[0].address_components[i].types[j]
                    ) {
                        case 'locality':
                            city =
                                response.results[0].address_components[i]
                                    .long_name;
                            break;
                        case 'administrative_area_level_1':
                            state =
                                response.results[0].address_components[i]
                                    .long_name;
                            break;
                        case 'country':
                            country =
                                response.results[0].address_components[i]
                                    .long_name;
                            break;
                        case 'postal_code':
                            zipcode =
                                response.results[0].address_components[i]
                                    .long_name;
                            break;
                    }
                }
            }
            setAddressData((prev: addressType) => ({
                ...prev,
                state: state,
                city: city,
                country: country,
                zipcode: zipcode,
                formattedAddress: address,
            }));
        },
        (error) => {
            console.error(error);
        }
    );
}
