import React from 'react';
type labelError = {
    error: string;
};
const LabelError: React.FC<labelError> = ({ error }) => {
    return (
        <>
            <p
                style={{
                    marginBottom: '-1rem',
                    fontSize: '12px',
                    color: 'red',
                }}
            >
                {error}
            </p>
        </>
    );
};

export default LabelError;
