import React from 'react'
import UserImg from '../../../assets/user-img.png'
import './Profile.scss'
const ProfileImage = (props: any) => {
    const { isdisable, handleImageChange, profileImg } = props
    return (
        <>
            <div className="col-sm-12 col-md-4">
                <div className="">
                    <span>
                        <img
                            src={profileImg ? profileImg : UserImg}
                            alt="user-img"
                        />
                    </span>
                    <div>
                        <p className="" style={{ fontSize: '13px' }}>
                            <i className="fa fa-camera" aria-hidden="true"></i>{' '}
                            Profile Image
                        </p>
                    </div>
                    <input
                        disabled={isdisable}
                        type="file"
                        name="images"
                        accept="image/*"
                        id="images"
                        onChange={(e) => handleImageChange(e)}
                        readOnly
                    />
                </div>
            </div>
        </>
    )
}

export default ProfileImage
