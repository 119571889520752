import React, { useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import PlaceOrder from '../pages/placeorder/PlaceOrder'
import './header.scss'

import { AuthContext } from '../context/UserContext'
import LogoutApi from '../../service/auth/LogoutApi'
import clsx from 'clsx'
import { successToaster } from '../Toast'
import config from '../../configs/config'
const Navbar = () => {
    const username = sessionStorage.getItem('username')
    const token = sessionStorage.getItem('token')
    const path = window.location.pathname.slice(1)
    const { authlink, setAuthLink } = useContext(AuthContext)
    const navigate = useNavigate()
    const Token = authlink?.Token

    const handleLogout = async () => {
        const result = await LogoutApi(token)
        if (result?.status) {
            setAuthLink((old: any) => ({ ...old, Token: '' }))
            sessionStorage.clear()
            successToaster(result?.message)
            navigate('/restaurants')
        }
    }
    return (
        <>
            <nav className="navbar navbar-expand-lg navbarmain MainHeader">
                <div className="container">
                    <NavLink to="/logo">
                        <div className="menunavlink">
                            <img
                                className="menunavimg"
                                src={config.logoPath}
                                alt="logo"
                            />
                        </div>
                    </NavLink>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <div className="hamburger-menu">
                            <div className="bar-top"></div>
                            <div className="bar-middle">Menu</div>
                            <div className="bar-bottom"></div>
                        </div>
                    </button>
                    <div
                        className="collapse navbar-collapse"
                        id="navbarSupportedContent"
                    >
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            {token?.length && (
                                <>
                                    <li
                                        className={clsx(
                                            'nav-item px-2 lidiv',
                                            path === 'dashboard' && 'activelink'
                                        )}
                                    >
                                        <NavLink
                                            className="nav-link linkwhite"
                                            aria-current="page"
                                            to="/dashboard"
                                        >
                                            Dashboard
                                        </NavLink>
                                    </li>
                                    <li className="nav-item px-2 lidiv">
                                        <PlaceOrder className="nav-link" />
                                    </li>
                                    <li
                                        className={clsx(
                                            'nav-item px-2 lidiv',
                                            path === 'orderhistory' &&
                                                'activelink'
                                        )}
                                    >
                                        <NavLink
                                            className="nav-link linkwhite"
                                            to="/orderhistory"
                                        >
                                            Order History
                                        </NavLink>
                                    </li>
                                    <li
                                        className={clsx(
                                            'nav-item px-2 lidiv',
                                            path === 'rewards' && 'activelink'
                                        )}
                                    >
                                        <NavLink
                                            className="nav-link linkwhite"
                                            to="/rewards"
                                        >
                                            Rewards
                                        </NavLink>
                                    </li>
                                    <li
                                        className={clsx(
                                            'nav-item px-2 lidiv',
                                            path === 'claims' && 'activelink'
                                        )}
                                    >
                                        <NavLink
                                            className="nav-link linkwhite"
                                            to="/claims"
                                        >
                                            Claims
                                        </NavLink>
                                    </li>
                                    <li className="nav-item dropdown px-2 lidiv vendorName">
                                        <span className="nav-link text-white fw-bold">
                                            <i
                                                className="fa fa-user"
                                                aria-hidden="true"
                                            ></i>{' '}
                                            {username}
                                        </span>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item py-2 px-1 mb-1 drop-menu-li navTitle">
                                                <NavLink
                                                    to="/profile"
                                                    className="mb-3 px-2 title"
                                                >
                                                    Profile
                                                </NavLink>
                                            </li>
                                            <li className="nav-item py-2 px-1 drop-menu-li navTitle">
                                                <NavLink
                                                    to=""
                                                    onClick={handleLogout}
                                                >
                                                    Logout
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar
