export const EnableDisableRegistration = (
    isemail: string,
    isname: string,
    ismobile: string,
    ispassword: string,
    isselect: number
) => {
    if (
        isemail === 'enable' &&
        isname === 'enable' &&
        ismobile === 'enable' &&
        ispassword === 'enable' &&
        isselect > 0
    ) {
        return false;
    } else if (
        isemail === 'disable' &&
        isname === 'disable' &&
        ismobile === 'disable' &&
        ispassword === 'disable' &&
        isselect === 0
    ) {
        return true;
    } else {
        return true;
    }
};

export const EnableDisableLogin = (
    isemail: string,
    ispassword: string,
    recaptcha: number
) => {
    if (isemail === 'enable' && ispassword === 'enable' && recaptcha > 0) {
        return false;
    } else if (
        isemail === 'disable' &&
        ispassword === 'disable' &&
        recaptcha === 0
    ) {
        return true;
    } else {
        return true;
    }
};
